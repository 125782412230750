import React from "react"
import { graphql } from "gatsby"

import { Audio, Layout, SEO, Video } from "../components"
// import soundfile from "../../static/sleepingatlast.mp3"

const Listen = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  //   const posts = data.allMarkdownRemark.edges

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Listen" />
	  <Video />
	  <Audio />
    </Layout>
  )
}

export default Listen
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "D MMMM YYYY")
            month: date(formatString: "MMM")
            day: date(formatString: "D")
            time
            ticket_link
            title
            description
          }
        }
      }
    }
  }
`
